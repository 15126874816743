import request from "./request";

// 查询导出压力分析列表
export const apiPressureAnalysisPreview = (data) => {
  return request({
    url: `/monitor/report/pressure-analysis/preview`,
    method: "POST",
    data,
  });
};

// 查询导出压力分析列表-导出
export const apiPressureAnalysisExport = (data) => {
  return request({
    url: `/monitor/report/pressure-analysis/export/excel`,
    method: "POST",
    data,
  });
};

// 查询导出压力分析详情
export const apiPressureDetail = (data) => {
  return request({
    url: `/monitor/report/pressure-analysis/detail/preview`,
    method: "POST",
    data,
  });
};

// 查询导出压力分析详情-导出
export const apiPressureDetailExport = (data) => {
  return request({
    url: `/monitor/report/pressure-analysis/detail/export/excel`,
    method: "POST",
    data,
  });
};
