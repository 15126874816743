import { isBlank } from "@/utils/validate.js";
import { handleParams } from "@/utils/index.js";

export default {
  data() {
    return {
      page: {
        size: 10,
        total: 0,
        current: 1,
      },
      tableData: [],
      loading: false,
    };
  },
  methods: {
    getList(current, api, flag = null, keyWord) {
      /* 
      current-当前页 
      api-调用的接口 
      flag-页面标识（notify-告警通知页面 alarm-告警记录页面）
      keyWord-含有下拉的筛选条件-需替换
      */
      this.loading = true;
      this.page.current = current;
      let obj = {
        size: this.page.size,
        current: this.page.current,
      };
      // 开始时间 结束时间处理
      if (
        flag &&
        Object.prototype.hasOwnProperty.call(this.form, "time") &&
        !isBlank(this.form.time) &&
        Object.prototype.toString.call(this.form.time) == "[object Array]"
      ) {
        let startKey = flag + "StartTime",
          endKey = flag + "EndTime";
        if (flag === "date") {
          startKey = "startDate";
          endKey = "endDate";
        }
        obj[startKey] = this.dayjs(this.form.time[0]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        obj[endKey] = this.dayjs(this.form.time[1]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
      }
      if (keyWord) {
        obj[keyWord] = this.form.key;
      }
      let params = Object.assign(obj, handleParams(this.form));
      api(params)
        .then((res = {}) => {
          this.page.total = res.total;
          this.tableData = res.records || [];
          this.currentAlarmSeq = params.alarmSeq;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 翻页
    currentPageChange(currentPage, api, flag = null, keyWord) {
      this.getList(currentPage, api, flag, keyWord);
    },
    // 重置
    reset(api, flag = null, keyWord) {
      this.form = this.$options.data().form;
      if (keyWord) {
        this.keyWord = this.$options.data().keyWord;
      }
      this.getList(1, api, flag, keyWord);
    },
  },
};
