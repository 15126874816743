import { mapActions } from "vuex";

export default {
  data() {
    return {
      options: {},
    };
  },
  mounted() {
    // 获取设备类型下拉
    if (
      (!this.cancelRequest || !this.$route.params.firstCategory) &&
      !this.getDeviceTypeFlag
    ) {
      this.getDeviceTypes().then((res) => {
        this.$set(this.options, "deviceTypeId", res);
        console.log(res, "res-设备类型");
      });
    }
  },
  methods: {
    ...mapActions({
      getDeviceTypes: "common/getAllDeviceTypeList",
    }),
  },
};
